<template>
  <div id="footer" class=" bg-gray-900 h-32 flex items-center justify-center text-white w-full">
      <div>
        <a class="px-3" href="./impressum">Imprint</a>
        <a class="px-3" href="./datenschutz">Datenschutz</a>
        <a class="px-3" href="https://github.com/DenisMathan">Github</a>
        <a class="px-3" href="./contact">Contact Me</a>
      </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>